import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import './assets/styles/index.css'

// router.beforeEach((to, from, next) => {
//     if(!store.state.q){
//       next({ name: 'Login' })
//     }else{
//       next()
//     }
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="relative h-full w-full min-h-screen">
    <header class="px-4 sm:px-12 py-4 flex justify-between items-center border-b">
      <img class="h-8 sm:h-8" src="../assets/ricoh-logo.svg" />
      <h1 class="sm:absolute sm:w-full sm:left-0 sm:text-center sm:text-xl font-bold tracking-wide">RICOH CONSULTING SERVICES</h1>
    </header>
    <div id="content">



      <div class="mx-12 text-2xl font-bold text-center my-8">

        <h2><span class="">Contact your Ricoh representitive for access to this page.</span></h2>

      </div>

    </div>

    <footer class="mt-8 w-full text-center absolute bottom-0 p-2 border-t bg-gray-50"><p>&copy;2024 Ricoh USA, Inc. All Rights Reserved.</p></footer>

  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {

  }
}
</script>

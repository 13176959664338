import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    q: undefined,
    originalData: undefined,
    schema: undefined,
    pageTitle: "Ricoh Consulting Services"
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
